<template>
  <!-- <v-row v-if="!sampleId">
    <v-col>
      Sample Not Found
    </v-col>
  </v-row> -->
  <v-row>
    <!-- <v-col cols="12" v-if="loading > 0">
      <LoadingBar />
    </v-col> -->
    <v-col cols="12">
      <!-- <v-col>{{ active_sample ? active_sample : "no active sample" }}</v-col> -->
      <!-- <img v-if="file" :src="file.dataUrl" alt="image" /> -->
      <!-- <div>{{ files.length ? files : "no files" }}</div>
      <div>{{ sampleId ? sampleId: "no sample id" }}</div> -->
      <!-- <div>{{ files.length ? files : "no files" }}</div> -->
      <image-uploader
        ref="fileInput"
        className="d-none"
        :debug="1"
        outputFormat="verbose"
        :preview="false"
        :capture="false"
        @input="setImage"
        accept=".png, .jpg, .jpeg"
        v-if="!readOnly"
        doNotResize="gif"
      >
        <label for="fileInput" slot="upload-label" ref="uploadLabel">
          <div
            style="display: flex; align-items: center; margin: auto; justify-content: center; gap: 4rem; flex-wrap: wrap; border: 1px dashed rgba(0, 0, 0, 0.25); padding: 20px; border-radius: 18px;"
          >
            <div style="display: flex; align-items: center; gap: 2rem;">
              <img
                src="../../../assets/feather_upload-cloud.svg"
                alt="upload"
              />
              <div
                style="display: flex; flex-direction: column; text-align: left; width: 100%; gap: 1rem;"
              >
                <p
                  style="margin: 0;
                  color: #000;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;"
                >
                  Select an image or drag and drop here
                </p>
                <p
                  style="
                margin: 0;
                color: rgba(0, 0, 0, 0.40);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;"
                >
                  JPG, JPEG or PNG file size no more than 10MB
                </p>
              </div>
            </div>
            <v-btn
              color="primary"
              style="background-color: #fff !important; color: #006940; border: 1px solid #006940; padding: 20px 16px; border-radius: 5px; box-shadow: none;"
              @click="$refs.uploadLabel.click()"
            >
              Select Image
            </v-btn>
          </div>
        </label>
      </image-uploader>
    </v-col>

    <v-col cols="12">
      <v-row style="display: flex; flex-wrap: wrap;">
        <template> </template>
        <div
          v-viewer
          style="display: flex;
          align-items: end;
          flex-wrap: wrap;"
        >
          <v-col
            v-for="file in files"
            :key="file[0]"
            class="d-flex child-flex"
            cols="6"
            sm="3"
            style="flex-direction: column; 
            gap: 1rem; width: 100%;
            min-width: 18rem;"
          >
            <v-container> </v-container>
            <img :src="imageUrls[file]" alt="sample-img" />
            <p>{{ file[1] }}</p>
            <v-btn color="primary" @click="downloadImage(file)">Download</v-btn>
            <v-btn color="error" @click="deleteImage(file)" v-if="!readOnly"
              >Delete</v-btn
            >
            <!-- <v-img
            :src="imageUrls[file]"
            alt="Image"
            v-if="imageUrls[file]"
            :lazy-src="lazySrc"
            aspect-ratio="1"
            class="sample-image grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container fluid class="image-overlay pa-2 fill-height">
              <v-row class="text-center">
                <v-col cols="12" v-if="file.error">
                  {{ file.error.msg }}
                </v-col>
                <v-col v-if="!readOnly">
                  <v-btn color="error" @click="deleteImage(file)">Delete</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="primary" @click="setDialog(file)">
                    View
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-img> -->
            <!-- <p>{{ file[1] }}</p> -->
            <!-- <img :src="file" style="width: 100%;" /> -->
            <!-- <img :src="imageUrls[file]" alt="Image" v-if="imageUrls[file]" /> -->
          </v-col>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import LoadingBar from "@/components/loaders/LoadingBar";
import { getBaseUrl } from "@/utils";
import { mapActions } from "vuex";
import ImageUploader from "vue-image-upload-resize";
import Vue from "vue";
import { mapFields } from "vuex-map-fields";
import "viewerjs/dist/viewer.css";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
Vue.use(Viewer);

// import ZoomableImage from "./ZoomableImage.vue";
// import imageZoom from "vue-image-zoomer";

export default {
  name: "SampleImages",
  props: {
    dzId: {},
    sampleId: {},
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: { ImageUploader },
  data() {
    return {
      loading: 0,
      id: "sample-images",
      url: "sample-images",
      files: [],
      errors: [],
      dialog: {
        active: false,
        file: {}
      },
      scale: 1,
      rotate: 0,
      posX: 0,
      posY: 0,
      isPanning: false,
      startX: 0,
      startY: 0,
      imageStyle: {
        transform: "scale(1) rotate(0deg) translate(0px, 0px)",
        transformOrigin: "center center"
      },
      // Transparent pixel
      file: null,
      lazySrc:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
      imageUrls: {},
      errorMessage: null,
      images: []
    };
  },
  mounted() {
    this.setLoading(1);
    this.getImages();
    this.images = this.imageUrls;
  },
  methods: {
    setLoading(val) {
      if (val < 0 && this.loading <= 0) {
        this.loading = 0;
        return;
      }
      this.loading = this.loading + val;
    },
    rotateLeft() {
      this.rotate -= 90;
      this.updateTransform();
    },
    rotateRight() {
      this.rotate += 90;
      this.updateTransform();
    },
    handleMouseMove(event) {
      // Adjust the mouse coordinates based on the rotation
      const { offsetX, offsetY, target } = event;
      const rect = target.getBoundingClientRect();

      // Calculate the normalized coordinates (from 0 to 1)
      const normX = offsetX / rect.width;
      const normY = offsetY / rect.height;

      // Map the normalized coordinates based on the current rotation
      const rotatedCoords = this.adjustForRotation(normX, normY, this.rotate);

      // Use rotatedCoords.x and rotatedCoords.y for the zoom logic
      this.applyZoom(rotatedCoords.x, rotatedCoords.y);
    },
    adjustForRotation(deltaX, deltaY, angle) {
      // Convert angle to radians
      const radians = (angle * Math.PI) / 180;
      // Rotate delta coordinates
      return {
        x: deltaX * Math.cos(radians) + deltaY * Math.sin(radians),
        y: -deltaX * Math.sin(radians) + deltaY * Math.cos(radians)
      };
    },
    applyZoom(x, y) {
      // Implement your zoom logic based on the adjusted coordinates
      console.log("Zoom applied at", x, y);
    },
    updateTransform() {
      // Update the transform for both scale and rotation
      this.imageStyle.transform = `scale(${this.scale}) rotate(${this.rotate}deg) translate(${this.posX}px, ${this.posY}px)`;
    },
    ...mapActions(["notify"]),
    ...mapActions("samples", ["getPaginated", "deleteSampleImage"]),
    setDialog(file) {
      this.dialog.active = true;
      this.dialog.file = file;
    },
    async deleteImage(file) {
      // if (this.deleteSampleImage(file)) {
      // this.files.splice(
      //   this.files.findIndex(item => item.id === file.id),
      //   1
      // );

      try {
        await Vue.axios.get(
          `/samples/camera/delete-image/${this.getDeleteLink(
            this.getShortLink(file[0])
          )}`,
          { responseType: "arraybuffer" }
        );
        const message = "Image Deleted Successfully";
        this.notify({ message });
        this.getImages();
      } catch (error) {
        console.error("Error deleting file:", file, error);
        const message = "Image Delete Failed";
        this.notify({ message });
      }

      // }
    },
    async downloadImage(file) {
      const image = await fetch(file[0]);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement("a");
      link.href = imageURL;
      link.download = `${file[1]}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getUrl(obj) {
      return `${getBaseUrl()}/${this.url}/${obj.id}`;
    },
    setImage(file) {
      this.file = file;
      if (file) {
        this.upload();
      }
    },
    dataURLtoBlob(dataurl) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    upload() {
      this.setLoading(1);
      let data = new FormData();

      // Convert data URL to Blob if necessary
      if (this.file && this.file.dataUrl) {
        let blob = this.dataURLtoBlob(this.file.dataUrl);
        data.append("stamp_image", blob, "filename.png"); // Specify a filename
      } else {
        // Ensure the file is not null or undefined
        if (this.file) {
          // Validate file type here
          const allowedTypes = ["image/jpeg", "image/png"];
          if (!allowedTypes.includes(this.file.type)) {
            this.setLoading(-1);
            this.notify({
              message:
                "Invalid file type. Please upload a JPEG, JPG or PNG image."
            });
            return;
          }

          data.append("stamp_image", this.file);
        } else {
          this.setLoading(-1);
          this.notify({ message: "No file to upload" });
          return;
        }
      }

      Vue.axios
        .post(
          `/samples/camera/upload-image/${
            this.active_sample.slug ? this.active_sample.slug : this.sampleId
          }`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(() => {
          const message = "Image Added Successfully";
          this.notify({ message });
        })
        .catch(error => {
          console.error(error);
          const message = "Image Upload Failed";
          this.notify({ message });
        })
        .finally(() => {
          this.setLoading(-1);
          this.getImages();
          this.$refs.fileInput.$el.querySelector('input[type="file"]').value =
            "";
        });
    },
    async streamImage() {
      try {
        const response = await Vue.axios.get(
          `/samples/samples/stream-image/${
            this.file.length ? this.files[0].splice : ""
          }`
        );
        const { data } = response;

        if (data) {
          this.files = data.imagePaths;
          // console.log(data);
          return true;
        } else {
          console.warn("No success in response:", data);
          return false;
        }
      } catch (error) {
        console.error("Error loading images!", error);
        return false;
      }
    },
    async getImages() {
      try {
        const response = await Vue.axios.get(
          `/samples/camera/get-image/${
            this.active_sample.slug ? this.active_sample.slug : this.sampleId
          }`
        );
        const { data } = response;

        // if (data) {
        //   this.files = data.imagePaths;
        //   this.files.forEach(file => {
        //     Vue.axios
        //       .get(`/${this.getShortLink(file)}`)
        //       .then(response => {
        //         // Handle the response
        //         console.log("File fetched successfully:", file, response);
        //       })
        //       .catch(error => {
        //         // Handle the error
        //         console.error("Error fetching file:", file, error);
        //       });
        //   });
        //   console.log(data);
        //   return true;
        // } else {
        //   console.warn("No success in response:", data);
        //   return false;
        // }
        if (data) {
          this.files = data.imagePaths;
          this.files.forEach(async file => {
            try {
              const fileResponse = await Vue.axios.get(
                `/${this.getShortLink(file[0])}`,
                { responseType: "arraybuffer" }
              );
              const base64 = await this.convertToBase64(fileResponse.data);

              // Store base64 URL in the component's data
              this.$set(this, "imageUrls", {
                ...this.imageUrls,
                [file]: base64
              });

              // console.log("File fetched successfully:", file, base64);
            } catch (error) {
              console.error("Error fetching file:", file, error);
            }
          });
          // console.log(data);
          return true;
        } else {
          console.warn("No success in response:", data);
          return false;
        }
      } catch (error) {
        console.error("Error loading images!", error);
        return false;
      }
    },
    getShortLink(file) {
      const apiIndex = file.indexOf("/api/");

      if (apiIndex !== -1) {
        return file.substring(apiIndex + 5); // +5 to skip the '/api/' part
      }

      return file;
    },
    getDeleteLink(file) {
      const parts = file.split("/stream-image/");
      return parts[1];
    },
    convertToBase64(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        // Assuming `data` is a Blob or ArrayBuffer
        reader.readAsDataURL(new Blob([data]));
      });
    }
  },
  computed: {
    ...mapFields("samples", [
      "active_sample.slug",
      "active_sample",
      "active_sample.test_components",
      "active_sample.lab_id",
      "adjusted_test_components"
    ])
  }
};
</script>

<style lang="scss">
.sample-image {
  .image-overlay {
    width: 100%;
    height: 100%;
    transition: opacity 100ms ease-in;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;

    &:hover {
      transition: opacity 300ms ease-in;
      opacity: 1;
    }
  }
}

.img-fluid {
  transform: rotate(90deg) !important;
}
</style>
